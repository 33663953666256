@import "~bootstrap/scss/bootstrap"
@import "./basedata/variables"

*
    font-family: "Open Sans", sans-serif
    color: $gray-400
    font-weight: 400

h6
    font-weight: 300
    color: $gray-700

.login-logo
    max-width: 140px

.biometry-status
    font-size: 12px
    border: 1px solid $gray-200
    border-radius: 8px
    padding: 6px
    display: inline-block

a.label
    text-decoration: none

    &:hover
        background: $pm-dark
.custom-navBar
    display: flex
    height: 100vh
    overflow: scroll initial
    position: fixed
    z-index: 2

    .pro-sidebar
        display: block
        z-index: 1029
        position: fixed
        transition: all 0.3s ease-in-out
        box-shadow: 1px 0 20px 0 #3f4d67
        width: 264px
        height: 100vh
        top: 0
        background: #3f4d67
        color: #a9b7d0

        .active
            li
                background: #5e6c9c
                border-radius: 8px

    .item-content
        font-size: 14px
        font-family: "Open Sans", sans-serif
        font-weight: 400
        color: $gray-300

    .head-div
        text-align: center !important
        // background-color: $gray-200

.bepass-logo
    text-align: center
    margin-top: 32px
    img
        width: 80%
        max-width: 80px
    i
        display: none

.toggled
    .bepass-logo
        span
            display: none
        i
            display: block
.logo-header
    img
        width: 80%
        // max-height: 70px

.text-coordinator
    font-size: 12px

.link-coordinator
    text-decoration: none
    text-transform: uppercase
    font-size: 10px

.unread td
    border-bottom-width: 0 !important
.cordinator-row
    background-color: $gray-100
    td
        border-top: 0

.tag-item
    display: inline
    margin: 0 8px 8px 0

.biometric-progress
    width: 200px

.display-table
    margin-bottom: 0 !important
    padding-bottom: 0 !important

.card-access
    .text-numbers
        display: block
        // font-size: 110px
        font-size: 4em

.toast-me
    .toast-body
        color: $white

.pikerpop
    position: absolute
    z-index: 2
    min-height: 100vh
    min-width: 100vw
    left: 0
    top: 0

.chrome-picker
    z-index: 3
    position: relative

.pickerColor
    width: 60px
    height: 25px
    border: 1px solid $gray-200
    padding: 2px
    border-radius: 4px

.copyright-botton
  background-color: $pm-primary
  padding: 6px 0
  text-align: center
  font-size: 12px
  color: $white
  margin-top: -30px
  a
    color: $white
    text-decoration: none