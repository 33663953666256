@import "./../../basedata/variables"

.bepass-modal
    .modal-title
        font-size: 16px
        text-align: center
        width: 100%
        color: $gray-700

    .btn-close
        opacity: 0.3
        font-size: 12px

    .modal-qr
        text-align: center

        p
            color: $gray-600

        img
            max-width: 50%
            margin: 16px 0

        .local-loader
            min-height: 200px

    .modal-footer.double-button
        flex-wrap: initial
        button
            width: 50%
