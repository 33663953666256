@import "./../../basedata/variables"

.bepass-form
    padding: 8px 25px

    &.top-line
        border-top: 1px solid $gray-200
    &.bottom-line
        border-bottom: 1px solid $gray-200
    .form-control,
    .form-floating
        font-size: 12px
        max-height: 40px

        input
            border: 1px solid $gray-300

        label
            color: $gray-600

    .form-floating>label
        padding: 0.5rem .75rem

    select
        width: 100%
        border: 1px solid $gray-300
        color: $gray-600

    .btn
        font-size: 12px
        height: 40px
        margin: 0
        &.btn-primary
            background: linear-gradient(-135deg, $blue 0%, #1dc4e9 100%)
            border: 0
